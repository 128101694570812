.NotFound-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1.5vmin);
  color: white;
}
.NotFound-h3 {
  padding: 5px;
}

.NotFound-h3,
.NotFound-h5 {
  margin: 0;
}

.NotFound-link { 
    color: #0040ff;
    textDecoration: underline;
}
