.footer {
  width: 100%;
  text-align: center;
  padding: 10px;
  background-color: #000000; 
  font-size: 14px; 
  position: fixed;
  box-shadow: 1 0px 8px rgb(29, 239, 42);
  bottom: 0;
  color:white;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-media-links {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.social-media-link {
  color: inherit;
  text-decoration: none;
  margin: 0 10px;
  font-size: 24px; 
}

.social-media-link:hover {
  color: #007BFF; 
  text-decoration: none;
}

.footer-text {
  margin-top: 0px;
}

.footer-text a {
  color: inherit; 
  text-decoration: none; 
}

.footer-text a:hover {
  text-decoration: none; 
}
