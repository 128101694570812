.App {
  text-align: center;
}

.App-logo {
  height: 100vmin;
  pointer-events: none;
  width: 200px; 
  height: 200px; 
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 40px;
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1.5vmin);
  color: white;
}

.App-h3 {
  padding: 5px;
}

.App-h3,
.App-h5 {
  margin: 0;
}

.App-h5 {
  margin-top: 5px;
}
.App-p {
}


.center {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #000000;
  border-radius: 10px; 
  /*border: 2px solid;
  box-shadow: 0 1px 2px rgba(249, 3, 3, 0.36);*/
  animation: border-color-change 8s infinite;
  margin: 10px 30px;
}

@keyframes border-color-change {
    0% { border-color: rgb(255, 0, 0); }
    10% { border-color: rgb(255, 165, 0); }
    20% { border-color: rgb(255, 255, 0); }
    30% { border-color: rgb(0, 255, 0); }
    40% { border-color: rgb(0, 0, 255); }
    50% { border-color: rgb(75, 0, 130); }
    60% { border-color: rgb(238, 130, 238); }
    70% { border-color: rgb(255, 99, 71); }
    80% { border-color: rgb(255, 20, 147); }
    90% { border-color: rgb(34, 139, 34); }
    100% { border-color: rgb(255, 255, 255); }
}


.center-image {
  width: 150px; 
  height: 150px; 
  border-radius: 50%; 
  margin-right: 20px; 
}

.center-info h2 {
  margin: 0;
  font-size: 24px; 
}

.center-info h2 a {
  color: inherit; 
  text-decoration: none; 
}

.center-info h2 a:hover {
  text-decoration: underline; 
}

.center-info p {
  margin: 5px 0;
  font-size: 16px; 
  color: #666; 
}

.center-info h6 {
  margin: 5px 0;
  font-size: 16px; 
  color: #666; 
}

.email-link {
  color: #00ff00; 
  text-decoration: none; 
  text-decoration: underline; 
}

.email-link:hover {
  text-decoration: underline; 
}
