.header {
  width: 100%;
  text-align: center;
  padding: 0px;
  background-color: #000000; 
  font-size: 14px; 
  position: fixed;
  box-shadow: 0 0px 8px rgb(29, 239, 42);
  top: 0; 
  color:white;
  z-index: 1000;
}

.header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-media-links {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.social-media-link {
  color: inherit;
  text-decoration: none;
  margin: 0 10px;
  font-size: 24px; 
}

.social-media-link:hover {
  color: #007BFF; 
  text-decoration: none;
}

.header-text {
  margin-top: 0px;
}

.header-text a {
  color: inherit; 
  text-decoration: none; 
}

.header-text a:hover {
  text-decoration: none; 
}
